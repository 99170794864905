var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('v-row',{attrs:{"justify":"center"}},[_c('v-dialog',{attrs:{"scrollable":"","origin":"top center","persistent":"","content-class":"warranty-dialog","max-width":_vm.dialogWidth},model:{value:(_vm.warrantyDialog),callback:function ($$v) {_vm.warrantyDialog=$$v},expression:"warrantyDialog"}},[_c('v-card',[_c('v-card-title',{staticClass:"headline dialog-custom-header white-background custom-border-bottom custom-header-blue-text"},[_vm._v(" Manage Warranty ")]),_c('v-card-text',{staticClass:"p-8 font-size-16"},[_c('v-form',{ref:"warrantyForm",attrs:{"lazy-validation":""},on:{"submit":function($event){$event.stopPropagation();$event.preventDefault();return _vm.updateWarranty($event)}},model:{value:(_vm.formValid),callback:function ($$v) {_vm.formValid=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"formValid"}},[_c('v-container',{attrs:{"fluid":""}},[_c('v-row',[_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-600"},[_vm._v("Start Date")]),_c('v-menu',{attrs:{"close-on-content-click":false,"nudge-right":40,"transition":"scale-transition","offset-y":"","min-width":"290px"},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
var attrs = ref.attrs;
return [_c('v-text-field',_vm._g(_vm._b({attrs:{"dense":"","filled":"","label":"Start Date","rules":[
                        function () { return !!_vm.formattedStartDate || 'Start Date is required'; }
                      ],"prepend-inner-icon":"mdi-calendar","readonly":"","solo":"","flat":"","value":_vm.formattedStartDate,"color":"cyan"}},'v-text-field',attrs,false),on))]}}]),model:{value:(_vm.startDatePicker),callback:function ($$v) {_vm.startDatePicker=(typeof $$v === 'string'? $$v.trim(): $$v)},expression:"startDatePicker"}},[_c('v-date-picker',{attrs:{"color":"cyan"},on:{"input":function($event){_vm.startDatePicker = false}},model:{value:(_vm.warrantyOption.start_date),callback:function ($$v) {_vm.$set(_vm.warrantyOption, "start_date", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warrantyOption.start_date"}})],1)],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"6"}},[_c('label',{staticClass:"font-weight-600"},[_vm._v("Serial No.")]),_c('v-text-field',{attrs:{"dense":"","filled":"","rules":[
                    function () { return !!_vm.warrantyOption.unique_id || 'Serial No. is required'; }
                  ],"color":"cyan","label":"Serial No.","solo":"","flat":""},model:{value:(_vm.warrantyOption.unique_id),callback:function ($$v) {_vm.$set(_vm.warrantyOption, "unique_id", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warrantyOption.unique_id"}})],1),_c('v-col',{staticClass:"py-0",attrs:{"md":"12"}},[_c('label',{staticClass:"font-weight-600"},[_vm._v("Remarks")]),_c('v-textarea',{attrs:{"auto-grow":"","dense":"","filled":"","solo":"","flat":"","color":"cyan","label":"Remarks","row-height":"20"},model:{value:(_vm.warrantyOption.description),callback:function ($$v) {_vm.$set(_vm.warrantyOption, "description", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warrantyOption.description"}})],1)],1),_c('table',{staticClass:"width-100"},[_c('tr',[_c('td',{attrs:{"colspan":"3"}},[_c('label',{staticClass:"font-weight-600"},[_vm._v("Duration")])])]),_vm._l((_vm.defaultItemWarranty),function(warranty,index){return _c('tr',{key:index},[_c('td',[_c('v-text-field',{directives:[{name:"mask",rawName:"v-mask",value:('###'),expression:"'###'"}],staticClass:"pr-2 width-100",attrs:{"dense":"","filled":"","label":"Duration","rules":[
                      function () { return !!warranty.value || 'Duration is required'; }
                    ],"solo":"","flat":"","color":"cyan"},model:{value:(warranty.value),callback:function ($$v) {_vm.$set(warranty, "value", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warranty.value"}})],1),_c('td',[_c('v-select',{staticClass:"pl-2 pr-2 width-100",attrs:{"items":_vm.warrantyDurationTypes,"dense":"","filled":"","solo":"","flat":"","rules":[
                      function () { return !!warranty.field || 'Duration Type is required'; }
                    ],"label":"Duration Type","color":"cyan","item-text":"text","item-value":"value","item-color":"cyan"},model:{value:(warranty.field),callback:function ($$v) {_vm.$set(warranty, "field", (typeof $$v === 'string'? $$v.trim(): $$v))},expression:"warranty.field"}})],1),_c('td',{staticStyle:{"vertical-align":"top"}},[(index > 0)?[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"red lighten-1","dark":"","fab":"","small":""},on:{"click":function($event){return _vm.removeWarranty(index, warranty.id)}}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-minus")])],1)]:[_c('v-btn',{staticClass:"mx-2",attrs:{"color":"cyan","dark":"","fab":"","small":""},on:{"click":_vm.pushWarranty}},[_c('v-icon',{attrs:{"dark":""}},[_vm._v(" mdi-plus")])],1)]],2)])})],2)],1)],1)],1),_c('v-divider',{staticClass:"my-0"}),_c('v-card-actions',[_c('v-spacer'),_c('div',{staticClass:"warrant-manage-action"},[_c('v-btn',{staticClass:"mx-2 custom-bold-button white--text",attrs:{"disabled":!_vm.formValid,"color":"cyan"},on:{"click":_vm.updateWarranty}},[_vm._v("Update ")]),_c('v-btn',{staticClass:"mx-2 custom-grey-border custom-bold-button",on:{"click":function($event){return _vm.$emit('remove:manage-warranty', _vm.currentIndex)}}},[_vm._v("Remove Warranty ")])],1)],1)],1)],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }