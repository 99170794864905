<template>
  <div class="btx-ck-editor">
    <ckeditor
      :editor="editor"
      :disabled="disabled"
      @input="onInput"
      :value="editorData"
      :config="editorConfig"
      @ready="init"
    ></ckeditor>
  </div>
</template>

<script>
import CKEditor from "@ckeditor/ckeditor5-vue2";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";

export default {
  model: {
    prop: "value",
    event: "input"
  },
  props: {
    value: {
      type: String,
      default: ""
    },
    disabled: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      editorReady: false,
      editor: ClassicEditor,
      editorData: "",
      editorConfig: {
        toolbar: [
          "heading",
          "|",
          "bold",
          "italic",
          "|",
          "outdent",
          "indent",
          "|",
          "bulletedList",
          "numberedList",
          "blockQuote",
          "|",
          "undo",
          "redo"
        ]
      }
    };
  },
  watch: {
    value() {
      this.$nextTick(() => {
        this.init();
      });
    }
  },
  methods: {
    onInput(value) {
      this.$emit("input", value);
    },
    init() {
      this.editorData = this.lodash.toString(this.value);
    }
  },
  components: {
    ckeditor: CKEditor.component
  }
};
</script>
