import { WarrantyEventBus } from "@/core/lib/warranty/warranty.lib";
import AppConfiguration from "@/core/config/app.config";
import moment from "moment-timezone";

moment.tz.setDefault(process.env.VUE_APP_TIMEZONE);

export default {
  data() {
    return {
      formValid: true,
      warrantyDialog: false,
      currentIndex: null,
      startDatePicker: null,
      warrantyOption: {
        start_date: moment().format("YYYY-MM-DD"),
        unique_id: null,
        description: null
      },
      defaultItemWarranty: new Array(),
      warrantyList: [
        { text: "30 Days", value: "30", type: "day" },
        { text: "6 Months", value: "182", type: "day" },
        { text: "1 Year", value: "365", type: "day" },
        { text: "2 Years", value: "730", type: "day" },
        { text: "3 Years", value: "1095", type: "day" }
      ],
      warrantyDurationTypes: [
        { text: "Day(s)", value: "day" },
        { text: "Week(s)", value: "week" },
        { text: "Month(s)", value: "month" },
        { text: "Year(s)", value: "year" }
      ]
    };
  },
  methods: {
    pushWarranty() {
      if (this.defaultItemWarranty.length < this.warrantyDurationTypes.length) {
        this.defaultItemWarranty.push({
          current: null,
          warranty: null,
          value: null,
          field: null
        });
      }
    },
    removeWarranty(index) {
      this.defaultItemWarranty.splice(index, 1);
    },
    closeDialog() {
      WarrantyEventBus.$emit("close:manage-warranty");
    },
    updateWarranty() {
      const _this = this;
      if (!_this.$refs.warrantyForm.validate()) {
        return false;
      }
      WarrantyEventBus.$emit("update:manage-warranty", {
        current: _this.currentIndex,
        data: {
          defaultItemWarranty: _this.defaultItemWarranty,
          warrantyOption: _this.warrantyOption
        }
      });
      _this.warrantyDialog = false;
    }
  },
  beforeDestroy() {
    /*WarrantyEventBus.$off("close:manage-warranty");
    WarrantyEventBus.$off("update:default-item-warranty");*/
  },
  created() {
    const _this = this;

    WarrantyEventBus.$on("close:manage-warranty", () => {
      this.warrantyDialog = false;
    });

    WarrantyEventBus.$on("update:default-item-warranty", param => {
      _this.currentIndex = param.current;
      _this.defaultItemWarranty = new Array();
      _this.warrantyOption = {
        start_date: moment().format("YYYY-MM-DD"),
        unique_id: null,
        description: null
      };
      if (_this.lodash.isEmpty(param.data) === false) {
        if (_this.lodash.isEmpty(param.data.warrantyOption) === false) {
          _this.warrantyOption = {
            start_date:
              param.data.warrantyOption.start_date ||
              moment().format("YYYY-MM-DD"),
            unique_id: param.data.warrantyOption.unique_id,
            description: param.data.warrantyOption.description
          };
        }
        if (_this.lodash.isEmpty(param.data.defaultItemWarranty) === false) {
          _this.defaultItemWarranty = param.data.defaultItemWarranty;
          _this.warrantyDialog = true;
        }
      }
    });
  },
  computed: {
    formattedStartDate() {
      let Config = AppConfiguration.get();
      if (Config) {
        if (!this.warrantyOption.start_date) {
          return moment().format(Config.dateFormat);
        }
        return moment(this.warrantyOption.start_date).format(Config.dateFormat);
      }
      return this.warrantyOption.start_date;
    }
  }
};
